body, html {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  color: #333;
  max-width: 1920px;
  margin: auto;
}

h1 {
  font-size: 46px;
  font-weight: 700;
  padding: 0;
  margin: 0
}

h2 {
  font-size: 24;
  font-weight: 300;
  padding: 0;
  margin: 0;
}

h3 {
  font-size: 18px;
  font-weight: 300;
  padding: 0;
  margin: 0;
}

a:hover {
  text-decoration: none;
}


ul {
  list-style-type: none;
  margin: 0;
  overflow: hidden;
}

@media only screen and (max-width: 1000px) {
  ul {
    padding: 20px 10px;
  }
  .my-container {
    padding: 20px 10px;
  }
  .special-underline {
    display: none;
  }
  .header {
    display: none;
  }
  .mobile-header {
    display: block;
  }
  .mobile-benefits {
    display: block;
  }
  #panel-benefits {
    display: none;
  }
  #panel-features {
    display: none;
  }
  .mobile-features {
    display: block;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 1000px) {
  ul {
    padding: 20px 100px;
  }
  .my-container {
    padding: 20px 100px;
  }
  .mobile-header {
    display: none;
  }
  .mobile-benefits {
    display: none;
  }
  .mobile-features {
    display: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1400px) {
  ul {
    padding: 20px 200px;
  }
  .my-container {
    padding: 20px 200px;
  }
  .mobile-header {
    display: none;
  }
  .mobile-benefits {
    display: none;
  }
  .mobile-features {
    display: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1800px) {
  ul {
    padding: 20px 350px;
  }
  .my-container {
    padding: 20px 350px;
  }
  .mobile-header {
    display: none;
  }
  .mobile-benefits {
    display: none;
  }
  .mobile-features {
    display: none;
  }
}

li {
  float: left;
}

li a {
  display: block;
  color: black;
  text-align: center;
  margin: 10px 20px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  position: relative;
}

.special-underline::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #730df1;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.special-underline:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

.special-underline:hover {
  color: #730df1 !important;
}

.button-link {
  border-radius: 16px;
  background-color: #730df1;
}

.button-link:hover {
  background-color: #610dc7;
}

.header {
  position: relative;
  text-align: center;
}

.header-text {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  width: 100%;
  border-radius: 16px;
  color: #3c077d;
}

.header-image {
}